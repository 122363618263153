// src/tokens.ts
export const tokens = {
  colors: {
    primary: '#8c705a',
    primaryLight: '#FFEDDE',
    primaryVariant: '#8A7353',
    secondary: '#4E7E6A',
    background: '#FFFFFF',
    surface: '#FFFFFF',
    error: '#B00020',
    success: '#13B007',
    successHover: '#0E7F05',
    onPrimary: '#fcf4ed',
    onSecondary: '#000000',
    onBackground: '#000000',
    onSurface: '#000000',
    onError: '#FFFFFF',
    grayLight: '#F5F5F5',
    gray: '#CCCCCC',
    grayMedium: '#595959',
    grayDark: '#333333',
    grayDarker: '#202020',
    black: '#0F0F0F',
    white: '#FFFFFF',
  },
  spacing: {
    small: '0.6rem',
    medium: '1rem',
    large: '1.5rem',
    extraLarge: '7rem',
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
    fontSize: '16px',
    fontWeight: 400,
  },
};
