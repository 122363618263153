import { useTheme } from "@mui/material";
//import { Link } from 'react-router-dom'

import { useTranslation } from "react-i18next";
// import ReCAPTCHA from 'react-google-recaptcha'
// import { add, isAfter } from 'date-fns'

export const Admin: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  return <>
   
  </>
};
